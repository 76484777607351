import './polyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router/index'
import VeeValidate from 'vee-validate'
import VueSession from 'vue-session'
import Axios from 'axios'
import store from './store'
import VuexFlash from 'vuex-flash';
import Toasted from 'vue-toasted';
import vSelect from 'vue-select'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import mixins from './mixins/global'
import vendor from './mixins/vendor'
import invoiceMixin from './mixins/invoiceMixin'
import VueJsonToCsv from 'vue-json-to-csv'
import VTooltip from 'v-tooltip'
import VCalendar from 'v-calendar';
import { Autocomplete } from 'buefy/dist/components/autocomplete'
import { Field } from 'buefy/dist/components/field'
import ToggleButton from 'vue-js-toggle-button'

import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import { backend_url } from './config';

Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

Vue.use(VueLodash, { lodash: lodash })
Vue.use(BootstrapVue)
Vue.use(VeeValidate, {fieldsBagName: 'formFields'})
Vue.use(VueSession)
Vue.component('v-select', vSelect)
Vue.use(vSelect)
Vue.use(VueJsonToCsv);
Vue.use(require('vue-moment'));
Vue.use(VuexFlash, { mixin: true });
Vue.use(VTooltip)
Vue.use(VCalendar);
Vue.use(ToggleButton)

Vue.component('b-field', Field)
Vue.component('b-autocomplete', Autocomplete)

Vue.use(Toasted, {
  iconPack : 'fontawesome',
  containerClass: 'rt-toasted'
});

Vue.prototype.$http = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

Vue.prototype.$axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

Vue.prototype.$axios.interceptors.request.use(function(config) {
  config.headers.Authorization = 'Bearer ' + Vue.prototype.$session.get('jwt');

  config.baseURL = backend_url
  return config;
});

Vue.prototype.$http.interceptors.request.use(function(config) {
  config.headers.Authorization = 'Bearer ' + Vue.prototype.$session.get('jwt');

  config.baseURL = backend_url
  return config;
});

// Vue.prototype.$axios.interceptors.request.use(function(config) {
//   config.headers.Authorization = 'Bearer ' + Vue.prototype.$session.get('jwt');
//   config.baseURL = 'https://rtnest.org';
//   return config;
// });
// Vue.prototype.$http.interceptors.request.use(function(config) {
//   config.headers.Authorization = 'Bearer ' + Vue.prototype.$session.get('jwt');
//   config.baseURL = 'https://rtnest.org';
//   return config;
// });


// Vue.prototype.$axios.interceptors.request.use(function(config) {
//   config.headers.Authorization = 'Bearer ' + Vue.prototype.$session.get('jwt');
//   config.baseURL = 'https://rtnest.org';
//   return config;
// });
// Vue.prototype.$http.interceptors.request.use(function(config) {
//   config.headers.Authorization = 'Bearer ' + Vue.prototype.$session.get('jwt');
//   config.baseURL = 'https://rtnest.org';
//   return config;
// });


// Vue.prototype.$axios.interceptors.request.use(function(config) {
//   config.headers.common['Access-Control-Allow-Origin'] = '*';
//   config.headers.Authorization = 'Bearer ' + Vue.prototype.$session.get('jwt');
//   config.baseURL = 'http://localhost:3000';
//   return config;
// });

// Vue.prototype.$http.interceptors.request.use(function(config) {
//   config.headers.common['Access-Control-Allow-Origin'] = '*';
//   config.headers.Authorization = 'Bearer ' + Vue.prototype.$session.get('jwt');
//   config.baseURL = 'http://localhost:3000';
//   return config;
// });

Vue.prototype.$http.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response.status === 401 || error.response.status === 403){
      Vue.prototype.$session.destroy()
      window.location.href = '/';
    }
    return Promise.reject(error);
});

Vue.prototype.$axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  store,
  components: {
    App,
  },
})
