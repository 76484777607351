import Vue from 'vue'
import Router from 'vue-router'

// Containers
const VendorContainer = () => import('@/containers/VendorContainer')
const AdminContainer = () => import('@/containers/AdminContainer')
const WtsContainer = () => import('@/containers/WtsContainer')
const WssContainer = () => import('@/containers/WssContainer')
const ManagerContainer = () => import('@/containers/ManagerContainer')
const AccountantContainer = () => import('@/containers/AccountantContainer')

//Dashboards
const Vendor = () => import('@/views/vendor/Vendor')
const Admin = () => import('@/views/admin/Admin')
const Wts = () => import('@/views/wts/Wts')
const Wss = () => import('@/views/wss/Wss')
const Manager = () => import('@/views/manager/Manager')

//Admin Pages
const AddCompany = () => import('@/views/admin/AddCompany')
const AllCompanies = () => import('@/views/admin/AllCompanies')
const AddManager = () => import('@/views/admin/AddManager')
const AddAccountant = () => import('@/views/admin/AddAccountant')
const Company = () => import('@/views/admin/Company')
const AdminWSS = () => import('@/views/admin/AdminWSS')
const AdminVendor = () => import('@/views/admin/AdminVendor')
const AdminEditProfile = () => import('@/views/admin/AdminEditProfile')
const AdminEditPassword = () => import('@/views/admin/AdminEditPassword')
const AdminCreateInvoice = () => import('@/views/admin/AdminCreateInvoice')

//Manager Pages
const ManagerAddWts = () => import('@/views/manager/ManagerAddWts')
const ManagerAllProjects = () => import('@/views/manager/ManagerAllProjects')
const ManagerAddProject = () => import('@/views/manager/ManagerAddProject')
const ManagerEditProject = () => import('@/views/manager/ManagerEditProject')
const ManagerEditProfile = () => import('@/views/manager/ManagerEditProfile')
const ManagerEditPassword = () => import('@/views/manager/ManagerEditPassword')
const ManagerAuthority = () => import('@/views/manager/ManagerAuthority')

const ManagerChart = () => import('@/views/manager/ManagerChart')
const ManagerChart2 = () => import('@/views/manager/ManagerChart2')
const ManagerChart3 = () => import('@/views/manager/ManagerChart3')
const ManagerCharts = () => import('@/views/manager/ManagerCharts')

const ManagerSearch = () => import('@/views/manager/ManagerSearch')
const ManagerInvoice = () => import('@/views/manager/ManagerInvoice')
const ManagerRoles = () => import('@/views/manager/ManagerRoles')
const ManagerBudget = () => import('@/views/manager/ManagerBudget')
const ManagerMasterInvoice = () => import('@/views/manager/ManagerMasterInvoice');
const ManagerInvestigationTable = () => import('@/views/manager/ManagerInvestigationTable');

//Accountant Pages
const AccountantEditProfile = () => import('@/views/accountant/AccountantEditProfile')
const AccountantEditPassword = () => import('@/views/accountant/AccountantEditPassword')
const AccountantAuthority = () => import('@/views/accountant/AccountantAuthority')
const AccountantSearch = () => import('@/views/accountant/AccountantSearch')
const AccountantInvoice = () => import('@/views/accountant/AccountantInvoice')
const AccountantRoles = () => import('@/views/accountant/AccountantRoles')
const AccountantBudget = () => import('@/views/accountant/AccountantBudget')
const AccountantMasterInvoice = () => import('@/views/accountant/AccountantMasterInvoice');
const AccountantInvestigationTable = () => import('@/views/accountant/AccountantInvestigationTable');
const AccountantOtherInvoices = () => import('@/views/accountant/AccountantOtherInvoices');
const AccountantCreateVendor = () => import('@/views/accountant/AccountantCreateVendor')
const AccountantListOfInvoices = () => import('@/views/accountant/AccountantViewInvoices')
const AccountantVendorEditInvoice = () => import('@/views/accountant/AccountantVendorEditInvoice')
const AccountantWtsInvoice = () => import('@/views/accountant/AccWtsInvoice')
const AccountantQbyteInvoice = () => import('@/views/accountant/AccountantQbyteInvoice')
const AllVendors = () => import('@/views/wts/AllVendor')
const InviteVendorWss = () => import('@/views/wts/InviteVendorWss')
const AccountantParameters = () => import('@/views/accountant/AccountantParameters')
const AccountantConfiguration = () => import('@/views/accountant/AccountantConfiguration')

//Vendor Pages
const VendorCreateInvoice = () => import('@/views/vendor/VendorCreateInvoice')
const VendorPresets = () => import('@/views/vendor/VendorPresets')
const VendorCreatePreset = () => import('@/views/vendor/VendorCreatePreset')
const VendorEditPreset = () => import('@/views/vendor/VendorEditPreset')
const VendorInvoice = () => import('@/views/vendor/VendorInvoice')
const VendorEditInvoice = () => import('@/views/vendor/VendorEditInvoice')

const VendorEditProfile = () => import('@/views/vendor/VendorEditProfile')
const VendorEditPassword = () => import('@/views/vendor/VendorEditPassword')
const VendorCompanyLogo = () => import('@/views/vendor/VendorCompanyLogo')
const AddSubVendorByVendor= () => import('@/views/vendor/AddSubVendorByVendor')



//Wss Pages
const WssInvoice = () => import('@/views/wss/WssInvoice')
const WssEstimates = () => import('@/views/wss/WssEstimates')
const WssEditProfile = () => import('@/views/wss/WssEditProfile')
const WssEditPassword = () => import('@/views/wss/WssEditPassword')
const WssMasterInvoice = () => import('@/views/wss/WssMasterInvoice')
const WssInvestigationTable = () => import ('@/views/wss/WssInvestigationTable')

//Wts Pages
const AddEmail = () => import('@/views/wts/AddEmail')
const WtsInvoice = () => import('@/views/wts/WtsInvoice')


const WtsEditProfile = () => import('@/views/wts/WtsEditProfile')
const WtsEditPassword = () => import('@/views/wts/WtsEditPassword')
const WtsProjects = () => import('@/views/wts/WtsProjects')
const WtsProject = () => import('@/views/wts/WtsProject')
const WtsWss = () => import('@/views/wts/WtsWss')
const WtsVendor = () => import('@/views/wts/WtsVendor')
const WtsCharts = () => import('@/views/wts/WtsCharts')
const WtsMasterInvoice = () => import('@/views/wts/WtsMasterInvoice')
const WtsInvestigationTable = () => import('@/views/wts/WtsInvestigationTable');


// Views - Pages
const Page404 = () => import('@/views/Page404')
const Page500 = () => import('@/views/Page500')
const Support = () => import('@/views/Support')
const Login = () => import('@/views/Login')
const Forgot = () => import('@/views/Forgot')
const RegisterVendor = () => import('@/views/RegisterVendor')
const RegisterWss = () => import('@/views/RegisterWss')

//All User Component
const CostCodeBucket = () => import('@/components/CostCodeBucket')
const CostCodeBucketChart = () => import('@/components/CostCodeBucketChart')

const CostCodeBucketCreate = () => import('@/components/CostCodeBucketCreate')
//const CostCodeBucket = () => import('@/components/CostCodeBucket')
const CostCenterCreate = () => import('@/components/CostCenterCreate')
const CostCenterCreateManager = () => import('@/components/CostCenterCreateManager')

const ServiceEstimates = () => import('@/components/ServiceEstimates')

const MasterInvoice = () => import('@/components/MasterInvoice');


let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/admin',
      name: 'Admin',
      redirect: '/admin',
      component: AdminContainer,
      meta: {
          title: 'Admin | Invoice | RTNest Inc.',
          requiresAuth: true,
          is_admin : true
      },
      children: [
        {
          path: '',
          name: 'Admin Dashboard',
          component: Admin,
          meta: {
              requiresAuth: true,
              is_admin : true
          }
        },
        {
          path: 'add-company',
          name: 'Admin Add Company',
          component: AddCompany,
          meta: {
              requiresAuth: true,
              is_admin : true
          }
        },
        {
          path: 'wss',
          name: 'Admin WSS',
          component: AdminWSS,
          meta: {
              requiresAuth: true,
              is_admin : true
          }
        },
        {
          path: 'vendor',
          name: 'Admin Vendor',
          component: AdminVendor,
          meta: {
              requiresAuth: true,
              is_admin : true
          }
        },
        {
          path: 'profile',
          name: 'Admin Profile',
          component: AdminEditProfile,
          meta: {
              requiresAuth: true,
              is_admin : true
          }
        },
        {
          path: 'password',
          name: 'Admin Password',
          component: AdminEditPassword,
          meta: {
              requiresAuth: true,
              is_admin : true
          }
        },
        {
          path: 'create-invoice',
          name: 'Admin Create Invoice',
          component: AdminCreateInvoice,
          meta: {
              requiresAuth: true,
              is_admin : true
          }
        },
        {
          path: 'all-companies',
          meta: { label: 'Companies'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: AllCompanies,
              meta: {
                requiresAuth: true,
                is_admin : true
              }
            },
            {
              path: ':id',
              name: 'Company',
              component: Company,
              meta: {
                label: 'Company Details',
                requiresAuth: true,
                is_admin : true
              }
            },
          ]
        },
        {
          path: 'add-manager',
          name: 'Admin Add Manager',
          component: AddManager,
          meta: {
            requiresAuth: true,
            is_admin : true
          }
        },
        {
          path: 'add-accountant',
          name: 'Admin Add Accountant',
          component: AddAccountant,
          meta: {
            requiresAuth: true,
            is_admin : true
          }
        },
      ],
    },
    {
      path: '/vendor',
      redirect: '/vendor',
      name: 'Vendor',
      component: VendorContainer,
      meta: {
        title: 'Vendor | Invoice | RTNest Inc.',
        requiresAuth: true,
        is_vendor: true
      },
      children: [
        {
          path: '',
          name: 'Vendor Dashboard',
          component: Vendor,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'presets',
          name: 'Vendor Presets',
          component: VendorPresets,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'create-invoice',
          name: 'Vendor Create Invoice',
          component: VendorCreateInvoice,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'create-preset',
          name: 'Vendor Create Preset',
          component: VendorCreatePreset,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'edit-preset',
          name: 'Vendor Edit Preset',
          component: VendorEditPreset,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'profile',
          name: 'Vendor Profile',
          component: VendorEditProfile,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'company-logo',
          name: 'Company Logo',
          component: VendorCompanyLogo,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'password',
          name: 'Vendor Password',
          component: VendorEditPassword,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'invoice',
          name: 'Vendor Invoice',
          component: VendorInvoice,
          meta: {
            requiresAuth: true,
            is_vendor: true
          }
        },
        {
          path: 'invoice/edit',
          name: 'Vendor Edit Invoice',
          component: VendorEditInvoice,
          meta: {
            requiresAuth: true,
            is_vendor: true
          },
        },
        {
          path: 'new',
          name: 'Add Vendor By Sub Vendor',
          component: AddSubVendorByVendor,
          meta: {
            requiresAuth: true,
            is_vendor: true
          },
        }
      ],
    },
    {
      path: '/pm',
      redirect: '/pm',
      name: 'Project Manager',
      component: WtsContainer,
      meta: {
        title: 'Project Manager | Invoice | RTNest Inc.',
        requiresAuth: true,
        is_wts: true
      },
      children: [
        {
          path: '',
          name: 'Project Manager Invoices',
          component: Wts,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'projects',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Project Manager Projects',
              component: WtsProjects,
              meta: {
                requiresAuth: true,
                is_wts: true
              },
            },
            {
              path: ':id',
              name: 'Project Manager Project',
              component: WtsProject,
              meta: {
                label: 'Project Details',
                requiresAuth: true,
                is_wts: true
              }
            },
          ]
        },
        {
          path: 'all-vendor',
          name: 'All Vendors',
          component: AllVendors,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'invite',
          name: 'Invite Wss/Vendor',
          component: InviteVendorWss,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'invoice',
          name: 'Project Manager Invoice',
          component: WtsInvoice,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'chart-1',
          name: 'Project Manager Budget VS Actuals',
          component: ManagerChart,
          meta: {
            requiresAuth: true,
            is_wts: true
          },
        },
        {
          component: ManagerChart2,
          name: 'Project Manager Actual Well Cost',
          path: 'chart-2',
          meta: {
            requiresAuth: true,
            is_wts: true
          },
        },
        {
          component: ManagerChart3,
          name: 'Project Manager Actual Project Cost',
          path: 'chart-3',
          meta: {
            requiresAuth: true,
            is_wts: true
          },
        },
        {
          path: 'buckets',
          name: 'Cost Code Buckets',
          component: CostCodeBucket,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'chart-buckets',
          name: 'Project Manager Cost Code Bucket Chart',
          component: CostCodeBucketChart,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'service-estimates',
          name: 'Project Manager Service Estimates',
          component: ServiceEstimates,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'buckets/create',
          name: 'Project Manager Create Cost Code Buckets',
          component: CostCodeBucketCreate,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'invoice-chart',
          name: 'Project Manager Invoice Chart',
          component: WtsCharts,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'master-invoice',
          name: 'Project Manager Master Invoice',
          component: MasterInvoice,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'investigation-table',
          name: 'Project Manager Investigation Table',
          component: WtsInvestigationTable,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'profile',
          name: 'Project Manager Profile',
          component: WtsEditProfile,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'password',
          name: 'Project Manager Password',
          component: WtsEditPassword,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
        {
          path: 'add-email',
          name: 'Add Vendor & WSS',
          component: AddEmail,
          meta: {
            requiresAuth: true,
            is_wts: true
          }
        },
      ],
    },
    {
      path: '/wss',
      redirect: '/wss',
      name: 'Wss',
      component: WssContainer,
      meta: {
        title: 'WSS | Invoice | RTNest Inc.',
        requiresAuth: true,
        is_wss: true
      },
      children: [
        {
          path: '',
          name: 'Invoices',
          component: Wss,
          meta: {
            requiresAuth: true,
            is_wss: true
          }
        },
        {
          path: 'master-invoice',
          name: 'Master Invoice',
          component: MasterInvoice,
          meta: {
            requiresAuth: true,
            is_wss: true
          }
        },
        {
          path: 'investigation-table',
          name: "Well Site Supervisor Investigation Table",
          component: WssInvestigationTable,
          meta: {
            requiresAuth: true,
            is_wss: true
          }
        },
        {
          path: 'estimates',
          component: WssEstimates,
          name: 'WSS Estimates',
          meta: {
            requiresAuth: true,
            is_wss: true
          },
        },
        {
          path: 'profile',
          name: 'WSS Profile',
          component: WssEditProfile,
          meta: {
            requiresAuth: true,
            is_wss: true
          }
        },
        {
          path: 'password',
          name: 'WSS Password',
          component: WssEditPassword,
          meta: {
            requiresAuth: true,
            is_wss: true
          }
        },
        {
          path: 'invoice',
          component: WssInvoice,
          name: 'WSS Invoice',
          meta: {
            requiresAuth: true,
            is_wss: true
          },
        },
      ],
    },
    {
      path: '/manager',
      redirect: '/manager',
      name: 'Manager',
      component: ManagerContainer,
      meta: {
        title: 'Manager | Invoice | RTNest Inc.',
        requiresAuth: true,
        is_manager: true
      },
      children: [
        {
          path: 'profile',
          name: 'Manager Profile',
          component: ManagerEditProfile,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'password',
          name: 'Manager Password',
          component: ManagerEditPassword,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'invoice-chart',
          name: 'Manager Invoice Chart',
          component: WtsCharts,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'authority',
          name: 'Manager Authority',
          component: ManagerAuthority,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'budget',
          name: 'Manager Budget',
          component: ManagerBudget,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'roles',
          name: 'Manager Roles',
          component: ManagerRoles,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'invoice',
          name: 'Manager Invoice',
          component: ManagerInvoice,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'master-invoice',
          name: 'Manager Master Invoice',
          component: MasterInvoice,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'buckets/create',
          name: 'Manager Create Cost Code Buckets',
          component: CostCodeBucketCreate,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'cost-center',
          name: 'Cost Center Create',
          component: CostCenterCreateManager,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'service-estimates',
          name: 'Manager Service Estimates',
          component: ServiceEstimates,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'buckets',
          name: 'Manager Cost Code Buckets',
          component: CostCodeBucket,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'investigation-table',
          name: "Manager Investigation Table",
          component : ManagerInvestigationTable,
        },
        {
          path: 'chart-buckets',
          name: 'Manager Cost Code Bucket Chart',
          component: CostCodeBucketChart,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'charts',
          name: 'Manager Charts',
          component: ManagerCharts,
          meta: {
            requiresAuth: true,
            is_manager: true
          },
        },
        {
          path: 'chart-1',
          name: 'Budget VS Actuals',
          component: ManagerChart,
          meta: {
            requiresAuth: true,
            is_manager: true
          },
        },
        {
          component: ManagerChart2,
          name: 'Actual Well Cost',
          path: 'chart-2',
          meta: {
            requiresAuth: true,
            is_manager: true
          },
        },
        {
          component: ManagerChart3,
          name: 'Actual Project Cost',
          path: 'chart-3',
          meta: {
            requiresAuth: true,
            is_manager: true
          },
        },
        {
          path: '/',
          name: 'Manager Search',
          component: ManagerSearch,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'add-pm',
          name: 'Manager Add Project Manager',
          component: ManagerAddWts,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'pm',
          name: 'Assign Project Manager',
          component: Manager,
          meta: {
            requiresAuth: true,
            is_manager: true
          }
        },
        {
          path: 'projects',
          component: {
            render (c) { return c('router-view') }
          },
          meta: {
            requiresAuth: true,
            is_manager: true
          },
          children: [
            {
              name: 'Projects',
              path: '',
              component: ManagerAllProjects,
              meta: {
                requiresAuth: true,
                is_manager: true
              }
            },
            {
              path: 'add-project',
              name: 'Manager Add Project',
              component: ManagerAddProject,
              meta: {
                requiresAuth: true,
                is_manager: true
              }
            },
          ]
        },
      ],
    },
    {
      path: '/accountant',
      redirect: '/accountant',
      name: 'Accountant',
      component: AccountantContainer,
      meta: {
        title: 'Accountant | Invoice | RTNest Inc.',
        requiresAuth: true,
        is_accountant: true
      },
      children: [
        {
          path: 'projects',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Accountant Projects',
              component: WtsProjects,
              meta: {
                requiresAuth: true,
                is_accountant: true,
              },
            },
            {
              path: ':id',
              name: 'Accountant Project',
              component: WtsProject,
              meta: {
                label: 'Project Details',
                requiresAuth: true,
                is_accountant: true
              }
            },
          ]
        },
        {
          path: 'invoice-chart',
          name: 'Accountant Invoice Chart',
          component: WtsCharts,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'parameters',
          name: 'Accountant Parameters',
          component: AccountantParameters,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'configuration',
          name: 'Accountant Configuration',
          component: AccountantConfiguration,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'all-vendor',
          name: 'All Vendors',
          component: AllVendors,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'invite',
          name: 'Invite Wss/Vendor',
          component: InviteVendorWss,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'accountant/pminvoice',
          name: 'Accountant PM Invoice',
          component: AccountantWtsInvoice,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'buckets/create',
          name: 'Accountant Create Cost Code Buckets',
          component: CostCodeBucketCreate,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'buckets',
          name: 'Accountant Cost Code Buckets',
          component: CostCodeBucket,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'add-pm',
          name: 'Accountant Add Project Manager',
          component: ManagerAddWts,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'cost-center',
          name: 'Cost Center Create',
          component: CostCenterCreateManager,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'resources',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Accountant Project Resources',
              component: WtsProjects,
              meta: {
                requiresAuth: true,
                is_accountant: true,
                is_resource: true,
              },
            },
            {
              path: ':id',
              name: 'Accountant Project Resource',
              component: WtsProject,
              meta: {
                label: 'Project Details',
                requiresAuth: true,
                is_accountant: true,
                is_resource: true,
              }
            },
          ]
        },
        {
          path: 'chart-buckets',
          name: 'Accountant Cost Code Bucket Chart',
          component: CostCodeBucketChart,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'chart-1',
          name: 'Budget VS Actuals',
          component: ManagerChart,
          meta: {
            requiresAuth: true,
            is_accountant: true
          },
        },
        {
          component: ManagerChart2,
          name: 'Actual Well Cost',
          path: 'chart-2',
          meta: {
            requiresAuth: true,
            is_accountant: true
          },
        },
        {
          component: ManagerChart3,
          name: 'Actual Project Cost',
          path: 'chart-3',
          meta: {
            requiresAuth: true,
            is_accountant: true
          },
        },
        {
          path: 'invoices/vendor/edit',
          name: 'Accountant Vendor Edit Invoice',
          component: AccountantVendorEditInvoice,
          meta: {
            requiresAuth: true,
            is_accountant: true
          },
        },
        {
          path: 'service-estimates',
          name: 'Accountant Service Estimates',
          component: ServiceEstimates,
          props: {
            header: true,
            content: true
          },
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'pms',
          name: 'Assign Project Managers',
          component: Manager,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'profile',
          name: 'Accountant Profile',
          component: AccountantEditProfile,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'password',
          name: 'Accountant Password',
          component: AccountantEditPassword,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'authority',
          name: 'Accountant Authority',
          component: AccountantAuthority,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'budget',
          name: 'Accountant Budget',
          component: AccountantBudget,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'invoice',
          name: 'Accountant Invoice',
          component: ManagerInvoice,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'invoices',
          name: 'Accountant Invoices',
          component: WtsInvoice,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'qbyte-invoice',
          name: 'Accountant Qbyte Invoice',
          component: AccountantQbyteInvoice,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: '/',
          name: 'Accountant Search',
          component: AccountantSearch,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'master-invoice',
          name: "Accountant Master Invoice",
          component : MasterInvoice,
          meta: {
            requiresAuth: true,
            is_accountant: true
          }
        },
        {
          path: 'investigation-table',
          name: "Accountant Investigation Table",
          component : AccountantInvestigationTable,

        },
        {
          path: 'vendor-invoices',
          name: "Vendor Invoices",
          component : AccountantOtherInvoices,
        },
        {
          path: 'create-vendor',
          name: "Create Vendor",
          component : AccountantCreateVendor,
        },
        {
          path : 'view-invoices',
          name : 'View Invoices',
          component : AccountantListOfInvoices
        }
      ],
    },
    {
      path: '',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Invoice | RTNest Inc.',
        guest: true
      }
    },
    {
      path: '/',
      redirect: '',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404,
          meta: {
            title: '404 | Invoice | RTNest Inc.',
            guest: true
          }
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500,
          meta: {
            title: '500 | Invoice | RTNest Inc.',
            guest: true
          }
        },
        {
          path: 'support',
          name: 'Support',
          component: Support,
          meta: {
            title: 'Support | Invoice | RTNest Inc.',
          }
        },
        {
          path: 'register-vendor',
          name: 'Register Vendor',
          component: RegisterVendor,
          meta: {
            title: 'Register | Invoice | RTNest Inc.',
            guest: true
          }
        },
        {
          path: 'register-wss',
          name: 'Register WSS',
          component: RegisterWss,
          meta: {
            title: 'Register | Invoice | RTNest Inc.',
            guest: true
          }
        },
        {
          path: 'forgot',
          name: 'Forgot',
          component: Forgot,
          meta: {
            title: 'Forgot Password | Invoice | RTNest Inc.',
            guest: true
          }
        },
      ]
    }
  ]
})
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

router.beforeEach((to, from, next) => {
  let user = Vue.prototype.$session.get('user');
  if(user){
    user = user.charAt(0).toUpperCase() + user.slice(1)
  }
  const jwt = Vue.prototype.$session.get('jwt');

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if(nearestWithTitle){document.title = nearestWithTitle.meta.title;}

  if(to.meta.requiresAuth) {
    if(user === null || !Vue.prototype.$session.exists()){
      next("/")
    }
    if (Vue.prototype.$session.exists()) {
      if(to.meta.is_admin) {
        if(user === 'Admin') {
          next()
        }
        else {
          next({name: "Login"})
        }
      }
      if(to.meta.is_vendor) {
        if(user === 'Vendor') {
          next()
        }
        else {
          next({name: "Login"})
        }
      }
      if(to.meta.is_wss) {
        if(user === 'Wss') {
          next()
        }
        else {
          next({name: "Login"})
        }
      }
      if(to.meta.is_wts) {
        if(user === 'Pm') {
          next()
        }
        else {
          next({name: "Login"})
        }
      }
      if(to.meta.is_manager) {
        if(user === 'Manager') {
          next()
        }
        else {
          next({name: "Login"})
        }
      }
      if(to.meta.is_accountant) {
        if(user === 'Accountant') {
          next()
        }
        else {
          next({name: "Login"})
        }
      }
    }
    else {
      next({name: 'Login'})
    }
  }
  else if(to.matched.some(record => record.meta.guest)) {
    if (to.path === '/register-wss' || to.path === '/register-vendor') {
      Vue.prototype.$session.destroy()
      next();
    } else if (Vue.prototype.$session.exists()) {
      if(user != null){
        if(user === 'Pm') {
          next({name: 'Project Manager'})
        }
        else {
          next({name: user})
        }
      }
      else{
        next()
      }
    }
    else{
      next()
    }
  }
  else {
    next()
  }
})

export default router

Vue.use(Router,router)
